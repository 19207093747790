import { Form, useLoaderData, useSubmit } from '@remix-run/react'
import { useState, useRef } from 'react'

export default function FindVehicle() {
	const {
		year,
		make,
		model,
		submodel,
		engine,
		yearOptions,
		makeOptions,
		modelOptions,
		submodelOptions,
		engineOptions,
		vehicleId,
		zip,
	} = useLoaderData()
	const submit = useSubmit()

	const form = [
		{
			name: 'year',
			selected: year,
			options: yearOptions,
		},
		{
			name: 'make',
			selected: make,
			options: makeOptions,
		},
		{
			name: 'model',
			selected: model,
			options: modelOptions,
		},
		{
			name: 'submodel',
			selected: submodel,
			options: submodelOptions,
		},
		{
			name: 'engine',
			selected: engine,
			options: engineOptions,
		},
	]

	const hasVehicleData = year && make && model && submodel && engine

	const handleMakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const form = e.currentTarget.parentElement
		if (!form) return

		// Reset model, submodel, and engine fields
		const modelSelect = form.querySelector('select[name="model"]') as HTMLSelectElement
		const submodelSelect = form.querySelector('select[name="submodel"]') as HTMLSelectElement
		const engineSelect = form.querySelector('select[name="engine"]') as HTMLSelectElement

		if (modelSelect) modelSelect.value = ''
		if (submodelSelect) submodelSelect.value = ''
		if (engineSelect) engineSelect.value = ''

		submit(form, {
			preventScrollReset: true,
		})
	}

	return (
		<div>
			<h2 className="my-2 text-3xl">Find your vehicle</h2>

			<Form
				id="find-vehicle-form"
				method="GET"
			>
				{form.map((field) => (
					<select
						onChange={field.name === 'make' ? handleMakeChange : (e) =>
							submit(e.currentTarget.parentElement, {
								preventScrollReset: true,
							})
						}
						name={field.name}
						key={field.name}
						className="input input-bordered my-2 w-full bg-white"
						defaultValue={field.selected}
					>
						Select {field.name}
						<option value="">Select {field.name}</option>
						{field.options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				))}
				<input
					type="text"
					name="zip"
					placeholder="Enter your ZIP code"
					defaultValue={zip}
					pattern="[0-9]{5}"
					maxLength={5}
					className="input input-bordered my-2 w-full bg-white"
				/>
				<input
					type="text"
					name="keyword"
					placeholder="Search for a part by keyword"
					className="input input-bordered my-2 w-full bg-white"
				/>
				<button
					type="submit"
					form="find-vehicle-form"
					className={`text-white mt-2 mb-8 btn btn-secondary w-full mt-4${hasVehicleData ? '' : 'btn-disabled'}`}
				>
					Search for parts
				</button>
			</Form>
		</div>
	)
}
